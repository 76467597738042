import Image from "next/image";
import React from "react";

function Error({ statusCode }) {
  return (
    <p>
      {statusCode ? (
        <section
          className="error-section pt-220 rpt-120 pb-100 rpb-80 rel z-1"
          style={{ backgroundImage: "url(/assets/images/hero-two-bg.png)" }}
        >
          <div className="flex items-center justify-center py-20">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="error-image wow zoomIn delay-0-2s">
                  <Image
                    width={400}
                    height={400}
                    src="/assets/images/404-error.png"
                    alt="404 Error"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <>
          <section
            className="error-section pt-220 rpt-120 pb-100 rpb-80 rel z-1"
            style={{ backgroundImage: "url(/assets/images/hero-two-bg.png)" }}
          >
            <div className="flex items-center justify-center py-20">
              <div className="row align-items-center">
                <div className="col-lg-7">
                  <div className="error-image wow zoomIn delay-0-2s">
                    <Image
                      width={400}
                      height={400}
                      src="/assets/images/404-error.png"
                      alt="404 Error"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* 404 Error Section End */}
        </>
      )}
    </p>
  );
}

Error.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

export default Error;
